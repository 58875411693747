/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.navbar-toggle').click(function(){
    $('.nav-primary').slideToggle();
    $('.dropdown-open').slideUp();
    $('.dropdown-open').removeClass('dropdown-open');
  });

  	if( window.innerWidth < 992 ) {
	  $('.menu-item-has-children > a').on('click', function(e){
	    e.preventDefault();

	    if( !$(this).next('.sub-menu').hasClass('dropdown-open') ) {
	      $('.dropdown-open').slideUp();
	      $('.dropdown-open').removeClass('dropdown-open');
	      $(this).next('.sub-menu').slideToggle();
	      $(this).next('.sub-menu').addClass('dropdown-open');
	    } else {
	      $('.dropdown-open').slideUp();
	      $('.dropdown-open').removeClass('dropdown-open');
	    }

	  });
	}

  $('.owl-carousel:not(.quote-carousel)').owlCarousel({
    items : 1,
    nav : true,
    navText : ['<i class="fa fa-caret-left" aria-hidden="true"></i>','<i class="fa fa-caret-right" aria-hidden="true"></i>'],
    dots : false,
  });

  $('.quote-carousel').owlCarousel({
    items : 1,
    nav : false,
    dots : false,
    autoplay : true,
    animateInClass : 'fadeIn',
    animateOut : 'fadeOut',
    loop : true,
  });

  $('.toggle-app-form').click(function(){
  	$('.app-form').slideToggle();
  });

})(jQuery); // Fully reference jQuery after this point.
